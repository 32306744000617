import React, { Component, Suspense} from 'react'
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
//import './App.scss';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import './i18n'
//const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
//const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./containers/Login/Login'));


class App extends Component {



  changeLanguage = (lang) => {

    this.props.i18n.changeLanguage(lang)
  }


  render() {
    const {t} = this.props;
    return (

      <div>


      <BrowserRouter>
        <div>
{
              this.props.auth && this.props.auth.sessionId ? (
                <div>

                  <Suspense fallback={<div>{t('general.loading')}</div>}>

                    <DefaultLayout />
                  </Suspense>
                </div>
              ) :
                (
                  <div>
                    <Suspense fallback={<div>{t('general.loading')}</div>}>
                      <Login />
                    </Suspense>
                  </div>
                )
            }

        </div>

      </BrowserRouter>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer.auth,
        //superGroup: state.systemStateReducer.superGroup,
    }
};

const mapDispatchToProps = (dispatch) => ({

});

export default  withTranslation()((connect(mapStateToProps, mapDispatchToProps)(App)));

