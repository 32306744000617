import { fork } from 'redux-saga/effects';

// import all sagas
import { watchSignin } from '../containers/Login/saga';
import { watchGetAutoverbund } from '../views/Dashboard/saga';
import { watchPutAutoverbund  } from '../views/Dashboard/saga';
//import { watchGetCategories } from '../views/Dashboard/sagaCategories';
//import { watchGetGroups } from '../views/Dashboard/sagaGroups';
//import { watchGetSuperGroups } from '../views/Dashboard/sagaSuperGroups';

export default function* rootSaga() {
 yield [
    fork(watchSignin),
 //   fork(watchGetClips),
  //  fork(watchSyncClip),
  //  fork(watchGetCategories),
  //  fork(watchGetGroups),
  //  fork(watchGetSuperGroups)
  fork(watchPutAutoverbund), 
    fork(watchGetAutoverbund)
    
 ];
}