import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'

import authReducer from '../containers/Login/reducer';
import carReducer from '../views/Dashboard/reducer'; 
/*import clipReducer from '../views/Dashboard/reducer';
import categoryReducer from '../views/Dashboard/reducerCategories';
import groupReducer from '../views/Dashboard/reducerGroups';
import superGroupReducer from '../views/Dashboard/reducerSuperGroups';
*/
import changeState from '../components/reducer'

export default combineReducers({
    routing: routerReducer,
    authReducer,
    carReducer,
    changeState, 
    /*clipReducer,
    categoryReducer,
    groupReducer,
    superGroupReducer
    */
   
})