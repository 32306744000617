import * as actionTypes from './action';

const initialState = {
    isLoading: false,
    error: null,
    updateDate: null,    
    cars: [ ]
};




const carReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_AUTOVERBUND_REQUEST: {
            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                cars: action.cars
            });
        }
        case actionTypes.GET_AUTOVERBUND_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                cars: action.cars
            });
        }
        case actionTypes.AUTOVERBUND_ERROR: {
            return Object.assign({}, state, {
                isLoading: false,
                error: action.payload.error
            });
        }

        case actionTypes.UPDATE_CARPRICE: {
            console.log("REDUCER CARS: ", state.cars);
            const car = state.cars.find(c => c.id == action.payload.car.id);
            car.initialOffer = action.payload.price;
           
            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                cars: [...state.cars]
            });
            


        }

        case actionTypes.CAR_SYNC_RESPONSE: {

            const cars = state.cars;

            return Object.assign({}, state, {
                isLoading: false,
                updateDate: Date.now(),
                cars: cars
            });
        }

        default:
            return state;
    }
}

export default carReducer;