
import { takeEvery, fork, actionChannel, put, take, call, race } from 'redux-saga/effects';
import '../../settings';

import * as actionTypes from './action'
const settings = require('../../settings');

/**
 * Saga watchers
 */
/*
export function *watchGetClips() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.GET_CLIPS_REQUEST)
    while (payload = yield take(reqChannel)) {
        yield fork(getClips, payload);
    }
}

export function *watchSyncClip() {
    let payload;
    const reqChannel = yield actionChannel(actionTypes.CLIP_SYNC_REQUEST)
    while (payload = yield take(reqChannel)) {
        yield fork(syncClip, payload);
    }
}
*/

export function *watchGetAutoverbund() {
    let payload;
    
    /*
    const reqChannel = yield actionChannel(actionTypes.GET_AUTOVERBUND_REQUEST)
    while (payload = yield take(reqChannel)) {
        console.log("Here we go in watchGETAUTOVERBUND.... generator function"); 
        yield fork(getAutoverbund, payload);
    }
    */

   yield fork(getAutoverbund);

  //   yield takeEvery(actionTypes.GET_AUTOVERBUND_REQUEST, getAutoverbund)

}



function *getAutoverbund() {
   
    try {
        let result = yield call(httpGetAutoverbund);
          
        if (result.status == 'ok') {
            yield put({ type: actionTypes.GET_AUTOVERBUND_SUCCESS, cars: result.cars });
        } else {
            // authorization error
        }
    } catch(error) {
        yield put({ type: actionTypes.AUTOVERBUND_ERROR, payload: error, error: true });
    }
}

const httpGetAutoverbund = () => { 
    
    return fetch( settings.mainurl + "/" + settings.api_base_path + "/car", 
     {
         method: 'GET',
        // mode: "no-cors",
         headers: {
             'Content-Type': 'application/json',
            //'Content-Type': 'text/plain',
        //     'Authorization' : 'Bearer ' + authToken
         }
     })
     .then((response) => response.json())
     .then((response) => response);
 }



 export function *watchPutAutoverbund() {

  
  /*
    let payload;

    const reqChannel = yield actionChannel(actionTypes.CAR_SYNC_RESPONSE)
    while (payload = yield take(reqChannel)) {
        console.log("Here we go in watchGETAUTOVERBUND.... generator function"); 
        yield fork(syncAutoverbund, payload);
    }
    
*/

   // console.log("We start talking... watchPutAutoverbund");
  // yield fork(syncAutoverbund);

     yield takeEvery(actionTypes.CAR_SYNC_RESPONSE, syncAutoverbund)

}


 const httpPutCar = (carid, price) => {

    var _joobsterJSON={       
        "initialOffer" : Number(price)  
      }
      console.log("In Saga.... putting content : ", _joobsterJSON);
    return fetch( settings.mainurl + "/" + settings.api_base_path + '/car/' + carid +'/price' ,  {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
      //  'Authorization' : 'Bearer ' + this.props.auth.sessionId
        },
        body:JSON.stringify(_joobsterJSON)
      }).then((res) => res.json())
      .then((data) => data );
    
}

function *syncAutoverbund(action) {

    if (action.payload.carid && action.payload.price ) {
    try {
        
        let result = yield call(httpPutCar, action.payload.carid, action.payload.price);
        if (result.status == 'ok') {
            console.log("Action is: ", action);
            yield put({ type: actionTypes.CAR_SYNC_RESPONSE, payload: { cars: result.cars } });

        } else {
            console.log('authorization problem'); 
            // authorization error
        }
    } catch(error) {
        yield put({ type: actionTypes.AUTOVERBUND_ERROR, payload: error, error: true });
    } 

}
}
