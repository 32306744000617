/**
 * CARS action types
 */

export const GET_AUTOVERBUND_REQUEST = 'GET_AUTOVERBUND_REQUEST'; 
export const GET_AUTOVERBUND_SUCCESS = 'GET_AUTOVERBUND_SUCCESS'; 
export const AUTOVERBUND_ERROR = 'AUTOVERBUND_ERROR'; 
export const CAR_SYNC_RESPONSE = "CAR_SYNC_RESPONSE";
export const CARS_ERROR = "CARS_ERROR";
export const UPDATE_CARPRICE = 'UPDATE_CARPRICE';

export const getAutoverbundRequest = (payload) => ({
    type: GET_AUTOVERBUND_REQUEST,
    payload
});

export const getAutoverbundSuccess = (payload) => ({
    type: GET_AUTOVERBUND_SUCCESS,
    payload
});

export const autoverbundError = (error) => ({
    type: AUTOVERBUND_ERROR,
    error
});

export const getAutoverbundSyncSuccess = (payload) => ({
    type: CAR_SYNC_RESPONSE,
    payload
});
export const autoverbundSyncError = (error) => ({
    type: CARS_ERROR,
    error
});

export const autoverbundUpdateCarPrice = (payload) => ({
    type: UPDATE_CARPRICE,
    payload
});
