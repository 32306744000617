import {fork, actionChannel, put, take, call} from 'redux-saga/effects';

import * as loginActions from './action';
//import * as clipActions from '../../views/Dashboard/action';
//import * as categoryActions from '../../views/Dashboard/actionCategories';
//import * as groupActions from '../../views/Dashboard/actionGroups';
//import * as superGroupActions from '../../views/Dashboard/actionSuperGroups';

const settings = require('../../settings');

/**
 * Saga watchers
 */
export function *watchSignin() {
    let payload;
    const reqChannel = yield actionChannel(loginActions.AUTH_SIGNIN_REQUEST)
    while ((payload = yield take(reqChannel))) {
        yield fork(signin, payload);
    }
}

/**
 * Auth Signin endpoint
 * @param {*} payload 
 */
const httpSignin = (payload) => { 
    return fetch( settings.mainurl + '/'  +settings.api_base_path +  '/signin', 
    {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    })
    .then((response) => response.json())
    .then((response) => response);
};

function *signin(action) {
    try {
        const result = yield call(httpSignin, action.payload)
   //     yield put({ type: clipActions.GET_CLIPS_REQUEST, payload: { authToken: result.auth.sessionId, groupId: result.auth.groupId, page: 1 } });
      //  yield put({ type: groupActions.GET_GROUPS_REQUEST, payload: { authToken: result.auth.sessionId, userId: result.auth.userId } });
      //  yield put({ type: superGroupActions.GET_SUPERGROUPS_REQUEST, payload: { authToken: result.auth.sessionId, groupId: result.auth.groupId } });
      //  yield put({ type: categoryActions.GET_CATEGORIES_REQUEST });
        

        console.log('login result is: ', result)
        if (result.status == 'ok') {
            yield put({ type: loginActions.AUTH_SUCCESS, payload: result.auth });
            

        } else {
            yield put({ type: loginActions.AUTH_ERROR, payload: {}, error: true });
        }
        
    } catch(error) {
        yield put({ type: loginActions.AUTH_ERROR, payload: error, error: true  });
    }
}

